<template>
  <div class="chat">
    <div id="chat_box" style="height: 1030px; width: 1500px; overflow: hidden; margin: 0 auto; padding: 10px 0 0 0">
      <div class="im_status_list" style="float: left">
        <div style="display: flex; justify-content: space-between;margin: 10px 20px; align-items: center; height: 50px">
          <div style="display: flex;align-items: center;">
            <div>
              <img :src="user.headIco" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 20px">
            </div>
            <div>
              <div class="user-name">{{user.nickName}}</div>
            </div>
          </div>
          <div class="hand op user-name" @click="showFriends = true" style="font-size: 24px; font-weight: 600"><i class="el-icon-circle-plus-outline"> </i></div>
        </div>
        <div class="im_list" style="width:100%; height: 800px; overflow-y: auto; overflow-x: hidden;" id="message_list" v-if="showStranger === false">
          <div class="tag-card">
            <div style="padding: 0 10px">
              <el-autocomplete
                  style="display:inline;"
                  value-key="nickName"
                  v-model="searchContent"
                  :fetch-suggestions="querySearchAsync"
                  @select="select_one"
                  placeholder="请输入搜索用户昵称"
                  :highlight-first-item="true"
                  :trigger-on-focus="false"
                  :clearable = "true"
              ></el-autocomplete>
            </div>
          </div>
          <!--        <div class="tag-card hand op" @click="toStranger">-->
          <!--          <div style="display: flex; justify-content: space-between;width: 340px; height: 60px; align-items: center">-->
          <!--            <div style="display: flex;height: 60px; align-items: center">-->
          <!--              <div>-->
          <!--                <img src="@/assets/imgs/index/zu875.png" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 12px">-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <div class="user-name">陌生人私信</div>-->
          <!--                <div class="user-content">快乐星球  发来了新消息</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div>-->
          <!--              <div class="user-content">06-21</div>-->
          <!--              <div class="user-tag">1</div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
          <div v-if="userData.uuid" @click="select_chat(userData, 1)" class="tag-card hand op" :style="{backgroundColor: userData.uuid === selectUuid ? '#191E41' : ''}">
            <div style="display: flex; justify-content: space-between;width: 340px; height: 60px; align-items: center">
              <div style="display: flex;height: 60px; align-items: center">
                <div>
                  <img :src="userData.headIco" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 12px">
                </div>
                <div>
                  <div class="user-name">{{userData.nickName}}</div>
                  <div class="user-content"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in chat_user_list" @click="select_chat(user.uuid === item.sendUserInfo.uuid ? item.receiveUserInfo : item.sendUserInfo, 1)" :key="index" class="tag-card hand op" :style="{backgroundColor: item.receiveUserInfo.uuid === selectUuid || item.sendUserInfo.uuid === selectUuid ? '#191E41' : ''}">
            <div style="display: flex; justify-content: space-between;width: 340px; height: 60px; align-items: center">
              <div style="display: flex;height: 60px; align-items: center">
                <div>
                  <img :src="user.uuid === item.sendUserInfo.uuid ? item.receiveUserInfo.headIco : item.sendUserInfo.headIco" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 12px">
                </div>
                <div>
                  <div class="user-name">{{user.uuid === item.sendUserInfo.uuid ? item.receiveUserInfo.nickName : item.sendUserInfo.nickName}}</div>
                  <div class="user-content">{{item.content}}</div>
                </div>
              </div>
              <div>
                <div class="user-content">{{item.updateTime.slice(5, 10)}}</div>
                <div class="user-tag" v-if="item.isRead !== 1"></div>
              </div>
            </div>
          </div>
        </div>
        <div style="width:100%" v-if="showStranger === true">
          <div class="tag-card" style="height: 36px">
            <div style="display: flex; justify-content: space-between; color: #FFFFFF; font-weight: 600; line-height: 36px">
              <div class="hand op" style="margin-left: 10px" @click="showStranger = false">< 返回所有消息</div>
              <div class="hand op" style="margin-right: 10px">一键已读 <i class="el-icon-s-open"></i></div>
            </div>
          </div>
          <div v-for="(item, index) in chat_user_list" @click="select_chat(user.uuid === item.sendUserInfo.uuid ? item.receiveUserInfo : item.sendUserInfo, 1)" :key="index" class="tag-card hand op">
            <div style="display: flex; justify-content: space-between;width: 340px; height: 60px; align-items: center">
              <div style="display: flex;height: 60px; align-items: center">
                <div>
                  <img :src="user.uuid === item.sendUserInfo.uuid ? item.receiveUserInfo.headIco : item.sendUserInfo.headIco" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 12px">
                </div>
                <div>
                  <div class="user-name">{{user.uuid === item.sendUserInfo.uuid ? item.receiveUserInfo.nickName : item.sendUserInfo.nickName}}</div>
                  <div class="user-content">{{item.content}}</div>
                </div>
              </div>
              <div>
                <div class="user-content">{{item.updateTime.slice(5, 10)}}</div>
                <div class="user-tag">1</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 1140px; height: 920px; overflow:hidden;border-radius: 5px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16); float: left">
        <div v-if="chat_type===0 || chat_type===1"  style="width: auto;height: auto;overflow:hidden;">
          <div style="min-height: 100vh; width: 100%;">
            <div v-if="chat_type===0 && chat_msg.length > 0" style="width: 100%;height: 50px;background: #fff;line-height: 50px;border-bottom: 1px solid #d8d8d8;text-align: center;font-size: 16px;font-weight: bold;color: #000000a1">
              {{ user.uuid === chat_msg[0].sendUserInfo.uuid ? chat_msg[0].receiveUserInfo.nickName : chat_msg[0].sendUserInfo.nickName }}</div>
            <div v-if="chat_type===0 && chat_msg.length === 0" style="width: 100%;height: 50px;background: #fff;line-height: 50px;border-bottom: 1px solid #d8d8d8;text-align: center;font-size: 16px;font-weight: bold;color: #000000a1">
              {{ userData.nickName }}</div>
            <div style="width: 100%;height: auto;background: #fff;">
              <div class="message_box" id="message_box" style="width: 100%;height: 650px;overflow-y: auto;display: flex;flex-direction: column-reverse;" @scroll.passive="scrollEvent">
                <label v-for="(item,index) in chat_msg" v-bind:key="index">

                  <!--                                文本-->
                  <div style="width: 94%;height: auto;overflow: hidden;margin: 2px 3%;" >
                    <!--                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 12px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>-->
                    <div style="width: 50px;height: 50px;color: #666;" :style="user.uuid === item.sendUserInfo.uuid?'float: right;margin-left: 10px;':'float: left;margin-tight: 10px;'"><img :src="item.sendUserInfo.headIco" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div v-if="chat_type===0" style="width: calc( 100% - 130px);height: 26px;line-height: 26px;color: #666;padding: 2px 10px;font-size: 12px;margin: auto;" :style="user.uuid === item.sendUserInfo.uuid?'text-align: right;':'text-align: left;'">
                      {{ item.sendUserInfo.nickName }}</div>
                    <div style="max-width: calc( 100% - 150px);width:auto;min-height: 40px;height: auto;color: #5b5b5d;overflow: hidden;background: #f6f6fc;padding: 5px 15px;border-radius: 8px;font-size: 15px;font-weight: bold;"  :style="user.uuid === item.receiveUserInfo.uuid?'float: left;border-top-left-radius: 0':'float: right;border-top-right-radius: 0'">
                      <pre v-html="transText(item.content)"></pre></div>
                    <div v-if="item.status === 1" style="height: 40px;line-height: 40px;color: red;font-size: 20px;margin: auto 10px;" :style="user.uuid === item.receiveUserInfo.uuid?'float: left;':'float: right;'"><i class="el-icon-warning"></i></div>
                  </div>
                  <!--                                        图片-->
                  <div v-if="item.type===2" style="width: 94%;height: auto;overflow: hidden;margin: 2px 3%; ">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 12px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div v-if="chat_type===0 && chat_user_list[chat_user_index].id<0" style="width: calc( 100% - 130px);height: 26px;line-height: 26px;color: #666;padding: 2px 10px;font-size: 12px;margin: auto;" :style="item.from_me===0?'text-align: left;':'text-align: right;'">
                      {{ item.nick_name }}</div>
                    <div style="max-width: calc( 100% - 150px);width:auto;height: auto;overflow: hidden;padding: 5px 15px;"  :style="item.from_me===0?'float: left;':'float: right;'">
                      <el-image
                          style="max-width: 500px;max-height: 500px;border-radius: 8px;"
                          :src="item.content.url"
                          @click="input_imgae(item.content.url)"
                          :preview-src-list="chat_Img_List">
                      </el-image>
                    </div>
                  </div>
                  <!--                                        名片-->
                  <div v-if="item.type===5" style="width: 94%;height: auto;overflow: hidden;margin: 2px 3%;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div style="width: 350px;height: 370px;overflow: hidden;background: #c08d47;border-radius: 8px;line-height: 25px;font-size: 15px;"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 100%;height: 180px;position: relative;">
                        <img src="" style="position: absolute;width: 100%;height: 180px;">
                        <div style="width: calc(100% - 20px);height: 50px;text-align: right;position: relative;padding-right: 20px;">
                          <div style="width: 40px;height: 50px;float:right;">
                            <img src="" style="width: 26px;height: 26px;margin: 12px 2px;">
                          </div>
                          <div style="width: 40px;height: 50px;float:right;">
                            <img src="" style="width: 26px;height: 26px;margin: 12px 2px;">
                          </div>
                        </div>
                        <div style="width: 100%;height: 80px;margin-top: 10px;position: absolute;">
                          <div style="width: 30%;height: 70px;float: left;margin: 5px 0;">
                            <img :src="item.content.head_ico" style="width: 70px;height: 70px;border-radius: 50px;margin-left: calc((100% - 70px)/2);">
                          </div>
                          <div style="width: 70%;height: 70px;float: left;margin: 5px 0;color: #fff;">
                            <div style="width: 100%;height: 30px;font-weight: bold;font-size: 16px;letter-spacing:3px">
                              {{ item.content.nick_name }}</div>
                            <div style="width: 100%;height: 40px;overflow:hidden;line-height: 20px;">新疆新土地城乡规划设计院XXXX</div>
                          </div>
                        </div>
                      </div>
                      <div style="width: 100%;height: 180px;overflow:hidden;margin-top: 10px;background:#242424;color:#b1b1b1; ">
                        <div style="width: 70%;height: 30px;margin: 45px 15% 0 15%;font-weight: bold;">13579668220</div>
                        <div style="width: 70%;height: 30px;margin: 0 15%;font-weight: bold;">www.baigongbao.com</div>
                        <div style="width: 70%;height: 60px;margin: 0 15%;font-weight: bold;">乌鲁木齐市水区红光山绿城广场2A写字楼</div>
                      </div>
                    </div>
                  </div>
                  <!--                                        索要名片弹窗-->
                  <div v-if="item.type===6" style="width: 94%;height: auto;margin: 2px 3%;overflow:hidden;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div style="width: 250px;height: 140px;padding: 10px;color: #5b5b5d;background: #f6f6fc;border-radius: 8px;line-height: 25px;font-size: 15px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 250px;height: 80px;text-align: center;border-bottom: 1px solid gainsboro">
                        {{ item.content.content }}</div>
                      <div style="width: 250px;height: 40px;margin: 10px 0;">
                        <div style="width: 125px;height: 40px;float: left;">
                          <button class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #b9b9b9;color: #fff;border: 0;">取消</button>
                        </div>
                        <div style="width: 125px;height: 100%;float: left;margin: auto;">
                          <button class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #ff9800;color: #fff;border: 0;">确定</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                                        文件-->
                  <div v-if="item.type===7" style="width: 94%;height: auto;overflow: hidden;margin: 2px 3%;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div class="hand op sc" @click="download(item.content.file_url,item.content.file_name)" style="width: calc( 100% - 120px);height: 80px;color: #5b5b5d;overflow: hidden;background: #f6f6fc;border-radius: 8px;font-size: 14px;"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 120px;height: 80px;float:left;"><img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/file.png" style="width: 60px;height: 60px;margin-top: 10px;"></div>
                      <div style="width: calc(100% - 170px);height: 80px;overflow:hidden;float:left;">
                        <div style="width: 100%;height: 80px;line-height: 80px;">{{ item.content.file_name }}</div>
                      </div>
                    </div>
                  </div>
                  <!--                                        好友弹窗-->
                  <div v-if="item.type===8" style="width: 94%;height: auto;margin: 2px 3%;overflow:hidden;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div style="width: 250px;height: 140px;padding: 10px;color: #5b5b5d;background: #f6f6fc;border-radius: 8px;line-height: 25px;font-size: 15px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 250px;height: 80px;text-align: center;border-bottom: 1px solid gainsboro">
                        {{ item.content.content }}</div>
                      <div style="width: 250px;height: 40px;margin: 10px 0;">
                        <div style="width: 125px;height: 40px;float: left;">
                          <button class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #b9b9b9;color: #fff;border: 0;">取消</button>
                        </div>
                        <div style="width: 125px;height: 100%;float: left;margin: auto;">
                          <button class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #ff9800;color: #fff;border: 0;">确定</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                                        商品-->
                  <div  v-if="item.type===9" style="width: 94%;height: auto;overflow: hidden;margin: 2px 3%;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div style="width: calc( 100% - 120px);height: 150px;color: #5b5b5d;overflow: hidden;background: #f6f6fc;border-radius: 8px;font-size: 14px;"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 150px;height: 150px;float:left;"><img :src="item.content.img" style="width: 150px;height: 150px;"></div>
                      <div style="width: calc(100% - 170px);height: 150px;overflow:hidden;float:left;">
                        <div style="width: 100%;height: 150px;line-height: 150px;padding: 0 10px;">
                          {{ item.content.name }}</div>
                      </div>
                    </div>
                  </div>
                  <!--                                        链接-->
                  <div v-if="item.type===11" style="width: 94%;height: auto;overflow: hidden;margin: 2px 3%;" >
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 12px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div v-if="chat_type===0 && chat_user_list[chat_user_index].id<0" style="width: calc( 100% - 130px);height: 26px;line-height: 26px;color: #666;padding: 2px 10px;font-size: 12px;margin: auto;" :style="item.from_me===0?'text-align: left;':'text-align: right;'">
                      {{ item.nick_name }}</div>
                    <div class="hand op sc" style="max-width: calc( 100% - 150px);width:auto;min-height: 40px;height: auto;color: #5b5b5d;overflow: hidden;background: #f6f6fc;padding: 5px 15px;border-radius: 8px;line-height: 40px;letter-spacing: 1px;font-size: 15px;"  :style="item.from_me===0?'float: left;border-top-left-radius: 0':'float: right;border-top-right-radius: 0'" @click="url(item.content.url)">
                      {{ item.content.content }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p style="color:#a6a6a6;float:right;" >点击查看</p></div>
                  </div>
                  <!--                                        邀请专家进入团队-->
                  <div v-if="item.type===12" style="width: 94%;height: auto;margin: 2px 3%;overflow:hidden;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div style="width: 250px;height: 140px;padding: 10px;color: #5b5b5d;background: #f6f6fc;border-radius: 8px;line-height: 25px;font-size: 15px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 250px;height: 80px;text-align: center;border-bottom: 1px solid gainsboro">
                        {{ item.content.name }}邀请您进入团队</div>
                      <div style="width: 250px;height: 40px;margin: 10px 0;">
                        <div style="width: 125px;height: 40px;float: left;">
                          <button class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #b9b9b9;color: #fff;border: 0;">取消</button>
                        </div>
                        <div style="width: 125px;height: 100%;float: left;margin: auto;">
                          <button class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #ff9800;color: #fff;border: 0;">确定</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                                        ROOM-->
                  <div v-if="item.type===13" style="width: 94%;height: auto;margin: 2px 3%;overflow:hidden;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div @click="url(item.content.url)" class="hand op sc" style="width: 200px;height: auto;color: #5b5b5d;background: #f6f6fc;border-radius: 8px;line-height: 25px;font-size: 15px;overflow:hidden;"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 100%;height: 200px;"><img :src="item.content.bg_img" style="width: 200px;height: 200px;"></div>
                      <div style="width: 96%;height: auto;margin: 5px 2%;text-align: left;font-size: 13px;min-height: 30px;">分享了ROOM{{ item.content.name }}</div>
                    </div>
                  </div>
                  <!--                                        邀请成为内部成员-->
                  <div v-if="item.type===14" style="width: 94%;height: auto;margin: 2px 3%;overflow:hidden;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div style="width: 250px;height: 140px;padding: 10px;color: #5b5b5d;background: #f6f6fc;border-radius: 8px;line-height: 25px;font-size: 15px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 250px;height: 80px;text-align: center;border-bottom: 1px solid gainsboro">
                        {{ item.content.content }}</div>
                      <div style="width: 250px;height: 40px;margin: 10px 0;">
                        <label v-if="item.content.is_check==0">
                          <div style="width: 125px;height: 40px;float: left;">
                            <button @click="cancel_in_room(item.id,index)"  class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #b9b9b9;color: #fff;border: 0;">取消</button>
                          </div>
                          <div style="width: 125px;height: 100%;float: left;">
                            <button @click="agree_in_room(item.id,index)"  class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #ff9800;color: #fff;border: 0;">确定</button>
                          </div>
                        </label>
                        <label v-if="item.content.is_check==1">
                          <div style="width: 250px;height: 40px;float: left;text-align: center;line-height: 40px;">已同意</div>
                        </label>
                        <label v-if="item.content.is_check==-1">
                          <div style="width: 250px;height: 40px;float: left;text-align: center;line-height: 40px;">已拒绝</div>
                        </label>

                      </div>
                    </div>
                  </div>
                  <!--                                        分享站内动态-->
                  <div v-if="item.type===17" style="width: 94%;height: auto;margin: 2px 3%;overflow:hidden;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div v-if="chat_type===0 && chat_user_list[chat_user_index].id<0" style="width: calc( 100% - 130px);height: 26px;line-height: 26px;color: #666;padding: 2px 10px;font-size: 12px;margin: auto;" :style="item.from_me===0?'text-align: left;':'text-align: right;'">
                      {{ item.nick_name }}</div>
                    <div @click="url(item.content.url)" class="hand op sc" style="width: 200px;height: auto;color: #5b5b5d;background: #f6f6fc;border-radius: 8px;line-height: 25px;font-size: 15px;overflow: hidden;"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 100%;height: 200px;"><img :src="item.content.thumb" style="width: 200px;height: 200px;"></div>
                      <div style="width: 96%;height: auto;margin: 5px 2%;text-align: left;font-size: 13px;min-height: 30px;">{{ item.content.content }}</div>
                    </div>
                  </div>
                  <!--                                        申请下载-->
                  <div v-if="item.type===18" style="width: 94%;height: auto;margin:2px 3%;overflow:hidden;">
                    <div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 13px;color: #666;"><Timeago :datetime="String(item.time_str)" :autoUpdate="true"></Timeago></div>
                    <div style="width: 50px;height: 50px;color: #666;" :style="item.from_me===0?'float: left;margin-right: 10px;':'float: right;margin-left: 10px;'"><img :src="item.head_ico" style="width: 45px;height: 45px;margin:2.5px 0;border-radius: 50px;"></div>
                    <div v-if="chat_type===0 && chat_user_list[chat_user_index].id<0" style="width: calc( 100% - 130px);height: 26px;line-height: 26px;color: #666;padding: 2px 10px;font-size: 12px;margin: auto;" :style="item.from_me===0?'text-align: left;':'text-align: right;'">
                      {{ item.nick_name }}</div>
                    <div style="width: 250px;height: auto;padding: 10px;color: #5b5b5d;background: #f6f6fc;border-radius: 8px;line-height: 25px;font-size: 15px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);"  :style="item.from_me===0?'float: left;border-top-left-radius: 0;':'float: right;border-top-right-radius: 0;'">
                      <div style="width: 250px;height: 80px;text-align: center;border-bottom: 1px solid gainsboro">
                        {{ item.content.content }}</div>
                      <div style="width: 250px;min-height: 80px;text-align: center;border-bottom: 1px solid gainsboro">
                        {{ item.content.reason }}</div>
                      <div style="width: 250px;height: 40px;margin: 10px 0;">
                        <div style="width: 125px;height: 40px;float: left;">
                          <button class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #b9b9b9;color: #fff;border: 0;">取消</button>
                        </div>
                        <div style="width: 125px;height: 100%;float: left;margin: auto;">
                          <button class="hand op sc" style="width: 85px;height: 40px;margin: 0 20px;border-radius: 8px;background: #ff9800;color: #fff;border: 0;">确定</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.type===100" style="width: 94%;height: auto;overflow: hidden;margin: 10px 3%;" >
                    <div style="width: 100%;height: 30px;line-height: 30px;text-align: center;font-size: 12px;color: #666;">{{ item.content }}</div>
                  </div>
                </label>
              </div>
            </div>
            <div class="emoji_select" v-if="emoji_is_select===1" >
              <div class="emoji hand" v-for="item in emotion" v-bind:key="item.name" @click="send_emoji(item.text)" >
                <img :src="ossurl+'emotion/'+item.name+'.png'" />
              </div>
            </div>
            <div class="chat_msg_input" style="width: 100%;height: 220px;margin-top: 5px;background: #fff;">
              <div class="chat_msg_input_top" style="width: calc(100% - 20px);padding: 0 10px">
                <div style="display: flex; justify-content: end">
                  <div class="hand op" @click="sendCard" style="width: 80px; font-size: 14px; text-align: center; padding: 2px 0; background-color: #1C7EFF; color: #FFFFFF; border-radius: 10px; margin: 10px 0 0 0">发送名片</div>
                </div>
              </div>

              <div ref="chat_input"  @paste="handlePaste" contentEditable="true" style="outline: none;width: 100%;height: 120px;overflow: auto;margin-bottom: 10px; margin-left: 10px" @click="handleSelection" @input="handleSelection" @keyup.ctrl.enter="send_text()">

              </div>
              <div style="width: calc(100% - 20px);height: 30px;margin: 0 10px;">
                <div class="hand op sc" style="width: 140px;height: 26px;line-height: 26px;float: right;background: #ffe7c4;text-align: center;font-size: 14px;font-weight: bold;border-radius: 8px;color: #000000a1" @click="send_text()"  >发送(Ctrl+Entel)</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="chat_type===2" style="width: 85%;height: 800px;">
          <div style="width: 100%;height: 50px;">好友顶部</div>
        </div>
        <div v-if="chat_type===3" style="width: 1140px; height: 1030px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);background-color: #F5F5F5; display: flex; align-items: center; justify-content: center">
          <div style="width: 200px; text-align: center">
            <img src="@/assets/imgs/index/kong.png">
            <div style="color: #999999; font-size: 16px; margin-top: 10px; font-weight: 600">您还未选中或发起聊天</div>
          </div>
        </div>

      </div>

      <!--添加私信-->
      <el-dialog class="event-form" :visible.sync="showFriends" width="740px">
        <div style="display: flex;">
          <div class="dialog-title">好友列表</div>
        </div>

        <div class="message-divider">
          <el-divider></el-divider>
        </div>

        <div class="message-body">
          <div v-for="(item, index) in friendsList" :key="index" class="hand">
            <div class="list-title" @click="item.show = !item.show"><i :class="!item.show ? 'el-icon-caret-right' : 'el-icon-caret-bottom'" style="font-size: 17px; margin: 0 22px"></i>{{item.name}}（{{item.friendArr.length}}）</div>
            <div style="display: flex; flex-wrap: wrap" v-if="item.show === true">
              <div style="display: flex; justify-content: center;" class="list-content" v-for="(friend, indexs) in item.friendArr" :key="indexs">
                <img class="head-ico" style="width: 40px; height: 40px; margin: auto 6px;" :src="friend.friendInfo.headIco">
                <div style="margin: auto 0; width: 70%">
                  <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                    {{friend.friendInfo.nickName}}
                  </div>
                  <div style="color: #AAAAAA; font-size: 14px">
                    <span v-if="friend.friendInfo.address.cityName">{{friend.friendInfo.address.cityName[1]}}</span><span v-if="friend.friendInfo.major && friend.friendInfo.address.cityName"> | </span>{{friend.friendInfo.major}}
                  </div>
                </div>
                <div @click="toMessageFriend(friend.friendInfo)" class="hand op list-btn" style="color: #FF9800; border: 1px #FF9800 solid">私信</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <el-drawer
          title="文件夹"
          :append-to-body="true"
          :before-close="handleClose"
          :visible.sync="innerDrawer">
        <div v-if="chat_files_list.length>0" class="chat_files_list" style="width: 100%;height: 780px;overflow-x: hidden;overflow-y: auto;">
          <div style="width: 94%;height: 50px;margin: 0 3%;" v-for="(item,index) in chat_files_list" :key="index">
            <div style="width: 85%;height: 50px;line-height: 50px;float: left;overflow:hidden;">{{ item.content.file_name }}</div>
            <div style="width: 15%;height: 50px;line-height: 50px;float: left;"><button style="width: 60px;height: 30px;" @click="download(item.content.file_url,item.content.file_name)">下载</button></div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
var client = {};

var requ = require("./emotion.js");

export default {
  name:"Im",
  components: {

  },
  computed: {
    user() {
      return this.$store.state.user
    },
    is_login(){
      return this.$store.state.is_login
    },
  },
  data () {
    return {
      // 新
      queryUuid: '',
      page: 1,
      more:1,
      pageSize: 12,
      chat_user_list:[],
      userData: {
        uuid: '',
        headIco: '',
        nickName: ''
      },
      selectUuid: '',

      iPage: 1,
      iPageSize: 10,
      iMore: 1,

      showStranger: false,

      showFriends: false,
      friendsList: [],

      searchContent: '',

      // 旧
      drawer: true,
      innerDrawer: false,

      chat_user_index:0,
      chat_admin_list:[],
      chat_admin_index:0,
      search_chat_user_list_value:'',
      chat_list_temp:[],
      search_like:'',
      chat_type:3,
      nowDate:null,    //存放年月日变量
      nowTime:null,   //存放时分秒变量
      timer: "",           //定义一个定时器的变量
      websocket:null,
      // chat_user_list_input:'',
      restaurants: [],
      files: [],
      selection: null,
      range: null,
      textNode: null,
      rangeStartOffset: null,
      calling:0,
      chat_content_page:2,
      chat_msg:[],
      chat_user_info:'',
      chat_group_user_info:'',
      chat_Img_List:[],
      // 应该渲染chat_msg里面的index加此字段，这个用于测试
      transfer_text_conteng:'',
      loading: false,
      ossurl:"https://baigongbao.oss-cn-beijing.aliyuncs.com/img/",
      text:"",
      emoji_is_select:0,
      emotion:[],

      video_call_user_name:"",
      call_chat_id:0,
      call_user_id:0,
      chat_files_list:[],
      group_user_list:[],
    }
  },

  created:function(){
    var that = this;
    this.getFriendsList();
    if (this.$route.query.uuid) {
      this.selectUuid = this.$route.query.uuid
      this.getUserInfo(this.selectUuid)

    }
    that.get_chat_list();

    this.$EventBus.$on("login", ({ login }) => {
      this.$nextTick(() => {
        // that.get_chat_list();
        // that.webSorketStart();
      });
    });

    this.$EventBus.$on("login_out", ({ login_out }) => {
      this.$nextTick(() => {
        this.websocket.close();
      });
    });

  },
  mounted:function () {
  },
  methods:{
    //搜索
    querySearchAsync(searchContent, cb) {
      let newList = [];
      let list = this.chat_user_list.filter(item => {
        return (this.user.uuid === item.sendUserInfo.uuid ? item.receiveUserInfo.nickName : item.sendUserInfo.nickName).includes(searchContent)
      })
      for (const item of list) {
        newList.push(this.user.uuid === item.sendUserInfo.uuid ? item.receiveUserInfo : item.sendUserInfo)
      }
      cb(newList)
    },

    // 显示陌生私信列表
    toStranger() {
      this.showStranger = true
    },

    // 发送名片
    sendCard() {
      this.newApi.shareVisitingCard({uuid: this.selectUuid}).then(res => {
        if (res.isSuccess === 1) {
          this.utils.sus(res.data);
          this.sendMsg('已向您发送名片，请前往空间查看')
        }
      })
    },

    // 选择好友私信
    toMessageFriend(item) {
      console.log(item)
      this.userData = item
      this.queryUuid = item.uuid
      this.select_chat(this.userData, 1)
      this.page = 1
      this.more = 1
      this.chat_user_list = []
      this.get_chat_list()
      this.showFriends = false
    },

    // 获取可私信好友列表
    getFriendsList() {
      this.newApi.getMyFriendGroupList({enrollId: this.id}).then(res => {
        for (const item of res.data) {
          item.show = false;
        }
        this.friendsList = res.data
      })
    },

    // 获取用户信息
    getUserInfo(uuid) {
      this.newApi.getUserInfo({uuid: uuid}).then(res => {
        if (res.isSuccess === 1) {
          this.userData = res.data
          this.select_chat(this.userData, 1)
        }
      })
    },

    uploadFile: function (file){   //渲染上传文件
      var that= this;
      for (let i = 0; i !== file.length; i++) {
        var data = {
          file:file[i]
        }
        if(file[i].type.indexOf('image') === 0){  //如果是图片
          that.upload_img(data);
        }else if(file[i].type.indexOf('video') === 0){
          that.upload_video(data);
        }else{
          that.upload_file(data);
        }
      }
    },

    // 记录光标位置等 （debounce防抖提升性能）
    handleSelection() {
      this.selection = getSelection()
      // 光标对象
      this.range = this.selection.getRangeAt(0)
      // 获取光标对象的范围界定对象
      this.textNode = this.range.startContainer
      // 获取光标位置
      this.rangeStartOffset = this.range.startOffset;
    },
    // 转dom对象
    parseDom(arg) {
      var objE = document.createElement("div");
      objE.innerHTML = arg;
      return objE.childNodes[0];
    },
    // 剪切板
    handlePaste(event) {
      var that = this;

      const items = (event.clipboardData || window.clipboardData).items;
      if (!items || items.length === 0) {
        that.err("未复制内容");
        return;
      }
      let file = items[0].getAsFile();
      if(file){
        if(file.type.indexOf('image') === 0){
          document.onpaste = function(){ return false; };
          this.upload(file,function(url){
            var html='<img src="'+url+'" style="width:80px;height:80px">';
            console.log(html);
            var parseDom1 = that.parseDom(html);
            // 在光标处插入dom
            that.range.insertNode(parseDom1)
            // 光标开始和光标结束重叠
            that.range.collapse(false);
          })
        }
      }
    },

    select_one(item) {
      this.select_chat(item, 1)
    },

    // 搜索完成列表打开im
    select_chat(item, type) {
      this.search_chat_user_list_value="";
      if (type) {
        this.iMore = 1
        this.iPage = 1
        this.chat_msg = []
      }
      this.loading = true
      this.selectUuid = item.uuid
      this.newApi.getUserMsgList({uuid: item.uuid, page: this.iPage, pageSize: this.iPageSize}).then(res => {
        if (res.isSuccess === 1) {
          this.chat_type = 0;
          for (const item of res.data) {
            this.chat_msg.push(item)
          }
          if (res.data.length < 10) {
            this.iMore = 0
          } else {
            this.iPage ++
          }
          this.loading = false
        }
      })
    },
    // 搜索列表
    search_chat_user_list(){
      this.chat_list_temp=[];
      for (var i = 0; i < this.chat_user_list.length; i++) {
        if(this.chat_user_list[i].nick_name.indexOf(this.search_chat_user_list_value) != -1){
          this.chat_list_temp.push(this.chat_user_list[i]);
        }
      }
    },

    // 关闭文件夹抽屉
    handleClose(done) {
      done();
    },
    // 监听message_box滑动事件
    scrollEvent() {
      var scrollTop = document.getElementById("message_box").scrollTop - 655;
      var scrollHeight = document.getElementById("message_box").scrollHeight;
      if(Math.abs(scrollTop)>=scrollHeight){
        if (this.loading===false && this.iMore === 1){
          this.select_chat(this.user.uuid === this.chat_msg[0].sendUserInfo.uuid ? this.chat_msg[0].receiveUserInfo : this.chat_msg[0].sendUserInfo);
        }
      }
    },
    // scrollEventList() {
    //   var scrollTop = document.getElementById("message_list").scrollTop;
    //   var scrollHeight = document.getElementById("message_list").scrollHeight - 805;
    //   if(Math.abs(scrollTop)>=scrollHeight){
    //     if (this.loading===false && this.more === 1){
    //       this.get_chat_list()
    //     }
    //
    //   }
    // },
    // 发送emoji
    send_emoji:function(name){
      this.text = this.text+name;
      this.set_emoji_is_select();
    },
    // emoji表情显示
    transText:function(text){
      var result = text.match(/\[.*?\]/g);
      if (result == null) {
        return text;
      }
      for (var i = 0; i < result.length; i++) {
        for (var j = 0; j < this.emotion.length; j++) {
          if (this.emotion[j].text == result[i]) {
            text = text.replace(result[i],'<img style="width:25px;height:25px;" src="'+this.ossurl+'emotion/'+this.emotion[j].name+'.png">');
            break;
          }
        }
      }
      return text;
    },
    // 发送消息按钮
    send_text:function(){
      var that = this;
      var imgReg = /<img.*?(?:>|\/>)/gi;
      var srcReg = /src=['"]?([^'"]*)['"]?/i;

      let chil =this.$refs.chat_input.innerHTML;
      var arr = chil.match(imgReg);
      var imgs = [];
      if(arr!=null && arr.length>0){
        for (var i = 0; i < arr.length && i < 10; i++) {
          var src = arr[i].match(srcReg);
          imgs.push(src[1]);
        }
      }
      var text = that.$refs.chat_input.innerText;

      if (text.split(" ").join("").length === 0 && imgs.length===0) {
        that.err("不能发送空白");return;
      }

      // var tid= that.chat_group_user_info.id;
      // var user_type = 0;
      // if (that.chat_user_list[that.chat_user_index].id>0){
      //   tid= that.chat_user_info.id;
      //   user_type = 1;
      // }
      if (text.length>0){
        that.sendMsg(text)
      }

      // if (imgs.length>0){
      //   for (let i=0;i<imgs.length;i++){
      //     var data = {
      //       content:{
      //         url:imgs[i]
      //       },
      //       head_ico:that.user.head_ico,
      //       nick_name:that.user.nick_name,
      //       uuid:that.user.uuid,
      //       fm:that.user.id,
      //       from_me:1,
      //       t:tid,
      //       type:2,
      //     }
      //     that.send_msg(data,2)
      //   }
      // }
      this.$refs.chat_input.innerHTML='';
    },

    // 新发布
    sendMsg(content) {
      let item = {}
      if (this.chat_msg.length > 0) {
        item = this.user.uuid === this.chat_msg[0].sendUserInfo.uuid ? this.chat_msg[0].receiveUserInfo : this.chat_msg[0].sendUserInfo
      } else {
        item = this.userData
      }
      this.newApi.sendMsg({uuid: item.uuid, content: content}).then(res =>{
        if (res.isSuccess == 1) {
          this.utils.sus(res.data)
          this.message_box_scroll()
          this.select_chat(item, 1)
        }
      })
    },

    // 发送信息
    send_msg:function(msg,type){
      var that = this;
      var chat_id = '';
      if(that.chat_type===0){
        chat_id = that.chat_user_list[that.chat_user_index].id;
      }else if (that.chat_type===1){
        chat_id = that.chat_admin_list[that.chat_admin_index].id;
      }
      var tid= this.chat_group_user_info.id;
      if (this.chat_user_list[this.chat_user_index].id>0){
        tid= this.chat_user_info.id;
      }
      if(type < 900){

        var aData = new Date();
        var time = Math.round(aData.getTime()/1000);
        var month =aData.getMonth() < 9 ? "0" + (aData.getMonth() + 1) : aData.getMonth() + 1;
        var date = aData.getDate() <= 9 ? "0" + aData.getDate() : aData.getDate();
        var hour = aData.getHours() <= 9 ? "0" + aData.getHours() : aData.getHours();
        var minute = aData.getMinutes() <= 9 ? "0" + aData.getMinutes() : aData.getMinutes();
        var time_str = aData.getFullYear() + "-" + month + "-" + date + " " + hour+ ":" + minute;
        msg.time_str = time_str;
        msg.time = time;
        that.chat_msg.unshift(msg);
        if(that.chat_type===0){
          that.chat_user_list[that.chat_user_index].time_str = time_str;
          if(type===1) {
            that.chat_user_list[that.chat_user_index].content = msg.content.content;
          }else if(type===2){
            that.chat_user_list[that.chat_user_index].content = "[图片]";
          }else if(type===4){
            that.chat_user_list[that.chat_user_index].content = "[视频]";
          }else {
            that.chat_user_list[that.chat_user_index].content = "未添加类型";
          }
          that.chat_user_list[that.chat_user_index].time = time;
          msg.chat_id = chat_id;
          msg.no_read = 0;
          msg.id = '';
          that.chat_user_list[that.chat_user_index].chat_list.unshift(msg);
          if(that.chat_user_list[that.chat_user_index].chat_list.length>10){
            that.chat_user_list[that.chat_user_index].chat_list.pop()
          }
          that.chat_user_list.sort(that.compare('time'));
          that.chat_user_index=0;
          localStorage.user_chat = JSON.stringify(that.chat_user_list);
        }else if (that.chat_type===1){
          that.chat_admin_list[that.chat_admin_index].time_str = time_str;
          that.chat_admin_list[that.chat_admin_index].content = msg.content;
          that.chat_admin_list[that.chat_admin_index].time = time;
          that.chat_admin_list[that.chat_admin_index].chat_list.unshift(msg);
          if(that.chat_admin_list[that.chat_admin_index].chat_list.length>10){
            that.chat_admin_list[that.chat_admin_index].chat_list.pop()
          }
          that.chat_admin_list.sort(that.compare('time'));
          that.chat_admin_index=0;
          localStorage.admin_chat = JSON.stringify(that.chat_admin_list);
        }
      }else {
        tid = that.call_user_id;
      }
      var content = JSON.stringify(msg.content)
    },
    // 获取聊天列表
    get_chat_list() {
      this.chat_user_list = []
      this.newApi.getMsgList().then(res =>{
        for (const item of res.data) {
          if (this.selectUuid !== item.receiveUserInfo.uuid && this.selectUuid !== item.sendUserInfo.uuid) {
            this.chat_user_list.push(item)
          }
        }
      })
    },

    // 清除聊天的所有未读消息条数
    clear_chat_no_read(){
      var that = this;
      var chat_id=0;
      if(that.chat_type===0){
        chat_id = that.chat_user_list[that.chat_user_index].id
        if(chat_id<0){
          that.group_user_list = [];

        }
      }else if(that.chat_type===1){
        chat_id = that.chat_admin_list[that.chat_admin_index].id
      }

    },
    download(url,filename) {
      var that = this;
      that.getBlob(url, function (blob) {
        that.saveAs(blob,filename);
      });
    },
    getBlob(url,cb){
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function() {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
      // window.location.href = url;
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        var body = document.querySelector("body");

        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        // fix Firefox
        link.style.display = "none";
        body.appendChild(link);

        link.click();
        body.removeChild(link);

        window.URL.revokeObjectURL(link.href);
      }
    },

    // 排序方法key是用哪个字段进行排序
    compare(key) {
      return function(a,b){
        var val1 = a[key];
        var val2 = b[key];
        return val2 - val1;
      }
    },
    // 打开emoji
    set_emoji_is_select:function(){
      var that = this;
      if (that.emoji_is_select===0){
        that.emoji_is_select=1;
      }else {
        that.emoji_is_select=0;
      }
    },
    // 将当前图片放入图片数组
    input_imgae:function(url){
      var that = this;
      that.chat_Img_List=[];
      that.chat_Img_List.push(url);
    },
    // 聊天框滑动至底部
    message_box_scroll:function(){
      var message_box = document.getElementById("message_box");
      message_box.scrollTop = 0;
    },

    // @人员
    content_input(e,uid){
      var that=this;
      if (uid>0) {
        that.order_list=[];
        var text = (this.text.split('@')).length-1;
        for (var i=0;i<that.chat_list_filder.length;i++){
          if(that.chat_list_filder[i].user_list){
            for (var j=0;j<that.chat_list_filder[i].user_list.length;j++){
              that.chat_list_filder[i].user_list[j].checked=false;
            }
          }
        }
      }else{
        if(e.data == "@") {
          this.msg('请@相关人员');
          that.check_user_to_aite_show=1;
        }
        if(text >= 2){
          that.check_user_to_aite_show=0;
          this.msg('不能连续@人!');return;
        }
      }
    },
    upload_img:function(params){
      var file = params.file;
      var that = this;
      console.log(file);
      var img_types = ["bmp","png","gif","jpg","jpeg"];
      var video_types = ["mp4","ogg","webm"];

      var t = this.getType(file.name).toLowerCase();
      var tid= this.chat_group_user_info.id;
      if (this.chat_user_list[this.chat_user_index].id>0){
        tid= this.chat_user_info.id;
      }
      if (img_types.indexOf(t) > 0) {
        this.upload(file,function(url){
          var obj = {
            content:{
              url:url
            },
            head_ico:that.user.head_ico,
            nick_name:that.user.nick_name,
            uuid:that.user.uuid,
            fm:that.user.id,
            from_me:1,
            t:tid,
            type:2,
          }
          that.send_msg(obj,2)
        })
      }else if (video_types.indexOf(t) > 0) {
        this.upload_video(params);
      }else {
        this.upload_file(params);
      }

    },
    getType(file){
      var filename=file;
      var index1=filename.lastIndexOf(".");
      var index2=filename.length;
      var type=filename.substring(index1+1,index2);
      return type;
    },
    url:function(url){
      url = url.replace(/\/pages\//, "/");
      url = url.replace(/\?id=/, "/");
      window.open(url,'_blank');
    },
    upload_video:function(params){
      var file = params.file;
      var that = this;
      var tid= this.chat_group_user_info.id;
      if (this.chat_user_list[this.chat_user_index].id>0){
        tid= this.chat_user_info.id;
      }
      var obj = {};
      this.upload(file,function(url){
        var video_url = url;
        that.ajax('save_video_img',{
          url:url
        },function(ret){
          var img_url = ret.data;

          var obj = {
            content:{
              video_url:video_url,
              img_url:img_url
            },
            head_ico:that.user.head_ico,
            nick_name:that.user.nick_name,
            uuid:that.user.uuid,
            fm:that.user.id,
            from_me:1,
            t:tid,
            type:4
          }
          that.send_msg(obj,4)
        })
      })
    },
    upload_file:function(params){
      var file = params.file;
      var that = this;
      var  file_name = file.name;
      var tid= this.chat_group_user_info.id;
      if (this.chat_user_list[this.chat_user_index].id>0){
        tid= this.chat_user_info.id;
      }
      this.upload(file,function(url){
        var obj = {
          content:{
            file_name:file_name,
            file_url:url
          },
          head_ico:that.user.head_ico,
          nick_name:that.user.nick_name,
          uuid:that.user.uuid,
          fm:that.user.id,
          from_me:1,
          t:tid,
          type:7
        }
        that.send_msg(obj,7)
      })

    },
  },
  directives:{
  }
}

</script>

<style scoped>
.chat {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/imgs/index/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.dialog-title {
  height: 30px;
  padding: 10px 0;
  font-weight: 600;
  margin: 0 auto;
  font-size: 20px;
}
.message-body {
  min-height: 200px;
  padding: 20px;
}

.title-form {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.list-title {
  width: 232px;
  height: 36px;
  font-size: 15px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  background-color: #F5F5F5;
  border: 1px #D4D6D9 solid;
}

.list-content {
  width: 308px;
  height: 64px;
  border: 1px #CED0D4 solid;
  margin: 0 10px 10px 0;
}

.list-btn {
  width: 78px;
  height: 24px;
  line-height: 24px;
  margin: auto 10px;
  border-radius: 5px;
  text-align: center;
}

/deep/ .event-form .el-dialog__header {
  padding: 0;
}

/deep/ .event-form .el-dialog__body {
  padding: 0;
}

.message-divider .el-divider--horizontal {
  margin: 0;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.chat_box{
  font-family:"微软雅黑"
}
/*滚动条凹槽的颜色，还可以设置边框属性*/
.new_chat_list::-webkit-scrollbar-track-piece {
  background-color:#f8f8f8;
}
/*//滚动条的宽度*/
.new_chat_list::-webkit-scrollbar {
  width:5px;
}
/*//滚动条的设置*/
.new_chat_list::-webkit-scrollbar-thumb {
  background-color:#dddddd;
  background-clip:padding-box;
  min-height:28px;
  border-radius: 8px;
}

/*滚动条凹槽的颜色，还可以设置边框属性*/
.message_box::-webkit-scrollbar-track-piece {
  background-color:#f8f8f8;
}
/*//滚动条的宽度*/
.message_box::-webkit-scrollbar {
  width:5px;
}
/*//滚动条的设置*/
.message_box::-webkit-scrollbar-thumb {
  background-color:#dddddd;
  background-clip:padding-box;
  min-height:28px;
  border-radius: 8px;
}
/*群成员滚动条凹槽的颜色，还可以设置边框属性*/
.chat_group_user_list::-webkit-scrollbar-track-piece {
  background-color:#f8f8f8;
}
/*群成员滚动条的宽度*/
.chat_group_user_list::-webkit-scrollbar {
  width:5px;
}
/*//滚动条的设置*/
.chat_group_user_list::-webkit-scrollbar-thumb {
  background-color:#dddddd;
  background-clip:padding-box;
  min-height:28px;
  border-radius: 8px;
}


.chat_files_list::-webkit-scrollbar-track-piece {
  background-color:#f8f8f8;
}
/*群成员滚动条的宽度*/
.chat_files_list::-webkit-scrollbar {
  width:5px;
}
/*//滚动条的设置*/
.chat_files_list::-webkit-scrollbar-thumb {
  background-color:#dddddd;
  background-clip:padding-box;
  min-height:28px;
  border-radius: 8px;
}

/deep/.el-textarea__inner{
  height: 110px;
  overflow-y: auto;
  border: 0;
  margin:0 0 10px 0;
  letter-spacing:0.5px;
}
/deep/.el-textarea__inner::-webkit-scrollbar {
  width:0px;
}

.chat_msg_input_top_ico{
  width: 25px;
  height: 25px;
  margin: 5px 5px;
  float: left;
}

.im_status_list{
  background:#191E41;
  width:360px;
  height: 920px;
  border-radius: 2px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
}
/*滚动条凹槽的颜色，还可以设置边框属性*/
.im_list::-webkit-scrollbar-track-piece {
  background-color:#f8f8f8;
}
/*//滚动条的宽度*/
.im_list::-webkit-scrollbar {
  width:5px;
}
/*//滚动条的设置*/
.im_list::-webkit-scrollbar-thumb {
  background-color:#dddddd;
  background-clip:padding-box;
  min-height:28px;
  border-radius: 8px;
}

.im_status_list img{
  width:50px;height:50px;border-radius:50%;margin:10px;
}

.im_status_list .user-name {
  color: #FFFFFF;
  font-size: 17px;
  line-height: 22px;
}

.im_status_list .tag-card {
  width: 100%;
  height: 60px;
  background-color: #2B3154;
  margin-bottom: 2px;
}

.im_status_list .user-content {
  color: #A2A2A2;
  font-size: 14px;
  width: 240px;
  line-height: 30px;
}

.im_status_list .user-tag {
  width: 10px;
  height: 10px;
  font-size: 12px;
  margin-left: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  background-color: #FF444C;
  color: #FFFFFF;
}



/*视频播放圆角*/
/deep/ .vjs-tech{
  border-radius: 8px;
  overflow: hidden;
}
/deep/ .video-js{
  border-radius: 8px;overflow: hidden;font-size: 5px;
}

.emoji_select{
  position: absolute; background:#FFF;box-shadow: 1px 1px 50px rgba(0,0,0,.3);
  z-index:9999;height: 295px;top: 350px;width: 510px;left: 495px;
  overflow:auto;padding:5px;
}
.emoji_select .emoji{
  width:30px;height:30px; float:left; margin:3px;
}
.emoji_select .emoji img{
  width:100%;height:100%;
}
.waiting{
  width: 334px;
  height: 610px;
  text-align:center; color:#FFF;
  padding-top:200px;
}
.local-stream {
  width: 100px;
  height: 150px;
  position:absolute;top:20px;
  right:20px;
}
.distant-stream {
  width: 334px;
  height: 610px;
}

.video_call{
  width: 334px;
  height: 610px;
  position:fixed;top:0;left:0;z-index:999999;background:#000;
}
.get_video_call{
  background:#4caf50;

  width:55px;height:55px;position:absolute;bottom:100px;
  left:139.5px;border-radius:50%;
}

.close_video_call{
  background:#f45454;

  width:55px;height:55px;position:absolute;bottom:20px;
  left:139.5px;border-radius:50%;
}

/deep/.el-input__inner{
  height: 35px;line-height: 35px;border-radius: 50px !important;
}
</style>
